import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Header from "./Header";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReadResource from "./ReadResource";
import GeneralizedForm from "./GeneralizedForm";
import SingleDevice from "./SingleDevice";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./Login";


const dataUrl = 'https://samxp.com/api';
//const dataUrl = 'http://127.0.0.1:8000';


function App() {
  return (
    <Router>

              <Routes>
                <Route path="/login" element={<Login />} />
                {/*<Route path="/logout" element={<LogoutButton />} />*/}
                <Route path="/" element={<ProtectedRoute><ReadResource baseUrl={dataUrl} resource="realtime_log" /></ProtectedRoute>} />
                  <Route path="/device/create" element={<ProtectedRoute><ProtectedRoute><SingleDevice /></ProtectedRoute></ProtectedRoute>} />
                <Route path="/device/update/:id" element={<ProtectedRoute><ProtectedRoute><SingleDevice /></ProtectedRoute></ProtectedRoute>} />
                {/*<Route path="/device" element={<ProtectedRoute><ReadResource baseUrl={dataUrl} actionsEnabled={true} /></ProtectedRoute>} />*/}
                <Route path="/:resource/create" element={<ProtectedRoute><GeneralizedForm formConfigUrl={`${dataUrl}/getHeaders`} dataUrl={dataUrl} /></ProtectedRoute>} />
                <Route path="/:resource/update/:id" element={<ProtectedRoute><GeneralizedForm formConfigUrl={`${dataUrl}/getHeaders`} dataUrl={dataUrl} /></ProtectedRoute>} />
                <Route path="/:resource" element={<ProtectedRoute><ReadResource baseUrl={dataUrl} actionsEnabled={true} /></ProtectedRoute>} />
                <Route path="/:resource/static" element={<ProtectedRoute><ReadResource baseUrl={dataUrl} staticTable={true} actionsEnabled={false} /></ProtectedRoute>} />
                 <Route path="/profile" element={<ProtectedRoute><ReadResource baseUrl={dataUrl} staticTable={true} actionsEnabled={false} /></ProtectedRoute>}/>
              </Routes>
    </Router>
  );
}

export default App;
