import React from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from "react";
import { Link, useLocation } from 'react-router-dom';

import {
    faHouse,
    faGaugeSimple,
    faTachometerAlt,
    faBars,
    faMicrochip,
    faToolbox,
    faSign,
    faDatabase,
    faContactBook,
    faUsers,
    faInstitution,
    faArrowLeft,
    faCircleArrowLeft,
    faCircleArrowRight,
    faArrowAltCircleDown,
    faArchive,
    faSignOut, faBarsProgress
} from "@fortawesome/free-solid-svg-icons";
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faDev } from '@fortawesome/free-brands-svg-icons';
import { getCurrentUser, logout } from "./axiosInstance";

const navItems = [
    { path: '/', name: 'Main Dashboard', id: 'navItemMainDashboard', icon: faBars },
    { path: '/device', name: 'Devices', id: 'navItemDevices', icon: faMicrochip },
    { path: '/organization', name: 'Organization', id: 'navItemOrganizations', icon: faInstitution },
    { path: '/custom_user', name: 'Users', id: 'navItemUsers', icon: faUsers },
    { path: '/contact', name: 'Contacts', id: 'navItemContacts', icon: faContactBook },
    { path: '/realtime_log/static', name: 'Data', id: 'navItemData', icon: faDatabase },
    { path: '/signature', name: 'Signature', id: 'navItemSignature', icon: faSign },
    { path: '/setting', name: 'Settings', id: 'navItemSettings', icon: faToolbox },
    { path: '/archive', name: 'Device Archive', id: 'navItemArchive', icon: faArchive },
];

let itemShow = true;

function toggleNav() {
    const sidebarMenu = document.getElementById('sidebarMenu');
    const navItems = document.querySelectorAll('.iot_main_nav_item span');
    navItems.forEach((navItem) => {
        if (navItem.style.display === 'none') {
            navItem.style.cssText = "display: flex; flex-direction: row";
            sidebarMenu.style.width = "250px";
            itemShow = true;
        } else {
            navItem.style.display = 'none';
            sidebarMenu.style.width = "auto";
            itemShow = false;
        }
    });

    const idBtnClose = document.getElementById('id_btn_open');
    const idBtnOpen = document.getElementById('id_btn_close');
    if (itemShow) {
        idBtnClose.style.display = 'block';
        idBtnOpen.style.display = 'none';
        const navItemsA = document.querySelectorAll('.iot_main_nav_item');
        navItemsA.forEach((navItem) => {
            navItem.style.cssText = "display: flex; flex-direction: row";
        });
    } else {
        idBtnClose.style.display = 'none';
        idBtnOpen.style.display = 'block';
    }
}

export default function Header({ childToParent }) {
    const location = useLocation();
    const [toggleNavIcon, setToggleNavIcon] = useState(faCircleArrowLeft);

    const handleNavClick = (item) => {
        // childToParent(item.id);
    };

    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <div>
            <header id='idforheader'>
                <nav id="sidebarMenu" className="d-lg-block sidebar bg-white">
                    <div className="position-sticky">
                        <div className="list-group list-group-flush mx-3 mt-4">
                            {navItems.map((item, index) => {
                                if (
                                    (item.path !== '/device' && item.path !== '/realtime_log/static') && !user?.is_staff && !user?.is_superuser
                                ) {
                                    return null;
                                }
                                return (
                                    <Link className="nav-link" to={item.path} onClick={() => handleNavClick(item)} key={index}>
                                        <li
                                            className={`iot_main_nav_item list-group-item list-group-item-action py-2 ripple ${location.pathname === item.path ? 'active' : ''}`}
                                            key={index}
                                            aria-current="true"
                                        >
                                            <FontAwesomeIcon className="me-3" icon={item.icon} /><span>{item.name}</span>
                                        </li>
                                    </Link>
                                );
                            })}

                            <FontAwesomeIcon id="id_btn_open" className="me-3 p-3" icon={faCircleArrowLeft} onClick={toggleNav} />
                            <FontAwesomeIcon id="id_btn_close" className="me-3 p-3" icon={faCircleArrowRight} onClick={toggleNav} style={{ display: 'none' }} />
                        </div>
                    </div>
                </nav>

                <nav id="main-navbar" className="navbar navbar-expand-lg navbar-light bg-white fixed-top">
                    <div className="container-fluid">
                        <button data-mdb-button-init className="navbar-toggler" type="button" data-mdb-collapse-init data-mdb-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fas fa-bars"></i>
                        </button>

                        <a className="navbar-brand p-3" href="#">
                            <FontAwesomeIcon className="me-3 " icon={faBarsProgress}/>
                            <span>IoT Device Management</span>
                            {/*<img src="https://mdbcdn.b-cdn.net/img/logo/mdb-transaprent-noshadows.webp" height="25" alt="MDB Logo" loading="lazy" />*/}
                        </a>

                        {/*<form className="d-none d-md-flex input-group w-auto my-auto">*/}
                        {/*    <input autoComplete="off" type="search" className="form-control rounded" placeholder='Search (ctrl + "/" to focus)' style={{ minWidth: '225px' }} />*/}
                        {/*    <span className="input-group-text border-0"><i className="fas fa-search"></i></span>*/}
                        {/*</form>*/}

                        <ul className="navbar-nav ms-auto d-flex flex-row">
                            <li className="nav-item p-3">

                                 <FontAwesomeIcon onClick={logout} id="id_btn_open" className="me-2 " icon={faSignOut} />
                                 {user?.username}
                            </li>

                        </ul>
                    </div>
                </nav>
            </header>
        </div>
    );
};
