import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import { hostUrl, apiUrl } from './config';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ReadResource = ({ baseUrl = hostUrl, staticTable = false, actionsEnabled = true }) => {
    const { resource: resourceParam } = useParams();
    const resource = resourceParam || 'realtime_log';
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [fieldOptions, setFieldOptions] = useState([]);
    const [pagination, setPagination] = useState({
        count: 0,
        next: null,
        previous: null,
        limit: 50,
        offset: 0,
    });

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedField, setSelectedField] = useState('Filter');
    const [filterValue, setFilterValue] = useState('');
    const [period, setPeriod] = useState('all');
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');

    const getAccessToken = () => localStorage.getItem('access_token');
    const getRefreshToken = () => localStorage.getItem('refresh_token');
    const setAccessToken = (token) => localStorage.setItem('access_token', token);

    const refreshAccessToken = async () => {
        try {
            const response = await axios.post(`${apiUrl}/token/refresh/`, {
                refresh: getRefreshToken(),
            });
            setAccessToken(response.data.access);
            return response.data.access;
        } catch (error) {
            console.error('Failed to refresh access token', error);
            return null;
        }
    };

    const axiosInstance = axios.create();

    axiosInstance.interceptors.request.use(
        async (config) => {
            let token = getAccessToken();
            if (token) {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000;

                if (decodedToken.exp < currentTime) {
                    token = await refreshAccessToken();
                }
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;
            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const newToken = await refreshAccessToken();
                if (newToken) {
                    originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                    return axiosInstance(originalRequest);
                }
            }
            return Promise.reject(error);
        }
    );

    const fetchData = () => {
        const query = new URLSearchParams({
            format: 'json',
            limit: pagination.limit,
            offset: pagination.offset,
            search: searchQuery,
            text_field: selectedField,
            text_value: filterValue,
            period: resource === 'realtime_log' ? period : undefined,
            custom_start: resource === 'realtime_log' ? customStart : undefined,
            custom_end: resource === 'realtime_log' ? customEnd : undefined,
        }).toString();

        axiosInstance.get(`${baseUrl}/${resource}/?${query}`)
            .then(response => {
                const responseData = response.data.results;
                setData(responseData);
                setPagination({
                    count: response.data.count,
                    next: response.data.next,
                    previous: response.data.previous,
                    limit: pagination.limit,
                    offset: pagination.offset,
                });

                if (responseData.length > 0) {
                    const keys = Object.keys(responseData[0]);
                    setHeaders(keys);
                }
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    };

    const fetchFieldOptions = () => {
        axiosInstance.get(`${baseUrl}/getFields/${resource}/`)
            .then(response => {
                const fieldNames = response.data.fields;
                setFieldOptions(fieldNames);
                if (fieldNames.length > 0 && !selectedField) {
                    setSelectedField('Filter'); // Set the initial filter field if not already set
                }
            })
            .catch(error => {
                console.error('There was an error fetching the field options!', error);
            });
    };

    useEffect(() => {
        fetchFieldOptions();
        fetchData();
    }, [baseUrl, resource, pagination.limit, pagination.offset, searchQuery, selectedField, filterValue, period, customStart, customEnd]);

    const handleNext = () => {
        if (pagination.next) {
            setPagination(prev => ({
                ...prev,
                offset: prev.offset + prev.limit,
            }));
        }
    };

    const handlePrevious = () => {
        if (pagination.previous) {
            setPagination(prev => ({
                ...prev,
                offset: prev.offset - prev.limit,
            }));
        }
    };

    const handlePageChange = (page) => {
        setPagination(prev => ({
            ...prev,
            offset: page * prev.limit,
        }));
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFieldChange = (e) => {
        setSelectedField(e.target.value);
    };

    const handleFilterValueChange = (e) => {
        setFilterValue(e.target.value);
    };

    const handlePeriodChange = (e) => {
        setPeriod(e.target.value);
    };

    const handleCustomStartChange = (e) => {
        setCustomStart(e.target.value);
    };

    const handleCustomEndChange = (e) => {
        setCustomEnd(e.target.value);
    };

    const handleClearFilters = () => {
        setSearchQuery('');
        setSelectedField('Filter');
        setFilterValue('');
        setPeriod('all');
        setCustomStart('');
        setCustomEnd('');
        fetchData();
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(pagination.count / pagination.limit);
        const currentPage = Math.floor(pagination.offset / pagination.limit);
        const pages = [];

        for (let i = 0; i < totalPages; i++) {
            pages.push(
                <button
                    key={i}
                    className={`btn ${i === currentPage ? 'btn-primary' : 'btn-outline-primary'}`}
                    onClick={() => handlePageChange(i)}
                    style={{ margin: '0 0.2rem' }}
                >
                    {i + 1}
                </button>
            );
        }

        return pages;
    };

    const formatHeader = (header) => {
        return header.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };

    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <div className="d-flex" style={{ height: '100vh', overflow: 'hidden' }}>
            <Header />
            <div className="d-flex flex-column flex-grow-1" style={{ overflow: 'hidden' }}>
                <main style={{ marginTop: '58px', flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                    <div className="content p-2" style={{ flex: '0 0 auto', maxWidth: '100%' }}>
                        <div className="container-fluid mt-3">
                            <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap border-bottom border-1">
                                <div className="p-2 flex-grow-1 text-center">
                                    <h3 className="mb-0" style={{ fontSize: '1.5rem', color: '#333' }}>{resource.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}</h3>
                                </div>
                                <div className="p-2">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip-search`}>Type here to search</Tooltip>}
                                    >
                                        <input
                                            id="searchQuery"
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            style={{ padding: '0.5rem', transition: 'all 0.3s ease' }}
                                        />
                                    </OverlayTrigger>
                                </div>
                                <div className="p-2">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip-select`}>Select a field to filter by</Tooltip>}
                                    >
                                        <select
                                            id="selectedField"
                                            className="form-control"
                                            value={selectedField}
                                            onChange={handleFieldChange}
                                            style={{
                                                padding: '0.5rem',
                                                transition: 'all 0.3s ease',
                                                background: '#f8f9fa',
                                                border: '1px solid #ced4da',
                                                color: '#495057',
                                                appearance: 'none',
                                                backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBvbHlnb24gcG9pbnRzPSIwLDAgNSw1IDEwLDBaIiBmaWxsPSIjNjc3IiBzdHJva2U9IiM2NzciIHN0cm9rZS13aWR0aD0iMCIvPgo8cG9seWdvbiBwb2ludHM9IjAsOCAtNSwzIC01LDgiIGZpbGw9IiM2NzciIHN0cm9rZT0iIzY3NyIgc3Ryb2tlLXdpZHRoPSIwIi8+Cjwvc3ZnPgo=)',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'right 0.75rem center',
                                                backgroundSize: '8px 10px'
                                            }}
                                        >
                                            <option value="Filter">Filter</option>
                                            {fieldOptions.map((option, index) => (
                                                <option key={index} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </OverlayTrigger>
                                </div>
                                <div className="p-2">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip-filter`}>Type value to filter by</Tooltip>}
                                    >
                                        <input
                                            id="filterValue"
                                            type="text"
                                            className="form-control"
                                            placeholder="Filter value..."
                                            value={filterValue}
                                            onChange={handleFilterValueChange}
                                            style={{ padding: '0.5rem', transition: 'all 0.3s ease' }}
                                        />
                                    </OverlayTrigger>
                                </div>
                                {resource === 'realtime_log' && (
                                    <div className="p-2">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-period`}>Select a timeline</Tooltip>}
                                        >
                                            <select
                                                id="period"
                                                className="form-control"
                                                value={period}
                                                onChange={handlePeriodChange}
                                                style={{ padding: '0.5rem', transition: 'all 0.3s ease' }}
                                            >
                                                <option value="all">All</option>
                                                <option value="24hours">Last 24 Hours</option>
                                                <option value="weekly">Weekly</option>
                                                <option value="fortnightly">Fortnightly</option>
                                                <option value="monthly">Monthly</option>
                                                <option value="custom">Custom Range</option>
                                            </select>
                                        </OverlayTrigger>
                                    </div>
                                )}
                                {period === 'custom' && (
                                    <div className="p-2 d-flex" style={{ flexWrap: 'wrap' }}>
                                        <div className="p-2">
                                            <input
                                                id="customStart"
                                                type="date"
                                                className="form-control"
                                                value={customStart}
                                                onChange={handleCustomStartChange}
                                                style={{ padding: '0.5rem', transition: 'all 0.3s ease' }}
                                            />
                                        </div>
                                        <div className="p-2">
                                            <input
                                                id="customEnd"
                                                type="date"
                                                className="form-control"
                                                value={customEnd}
                                                onChange={handleCustomEndChange}
                                                style={{ padding: '0.5rem', transition: 'all 0.3s ease' }}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="p-2 d-flex align-items-end me-2">
                                    <button className="btn btn-primary me-2" onClick={fetchData}>
                                        <FontAwesomeIcon icon={faFilter} />
                                    </button>
                                    <button className="btn btn-secondary" onClick={handleClearFilters}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                </div>
                                {actionsEnabled && !staticTable && (user?.is_staff || user?.is_superuser) && (
                                    <div className="p-2">
                                        <Link className="btn btn-primary" to={`/${resource}/create/`}>
                                            <FontAwesomeIcon icon={faAdd} /> Add New
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: 1, overflow: 'auto' }}>
                        <table className="table table-striped table-hover" style={{ minWidth: '100%' }}>
                            <thead className="thead-dark">
                                <tr>
                                    {headers.map((header, index) => (
                                        <th key={index} scope="col">{formatHeader(header)}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}
                                        style={{ cursor: actionsEnabled && !staticTable ? 'pointer' : 'default' }}
                                        onClick={actionsEnabled && !staticTable ? () => navigate(`/${resource}/update/${item.id}`) : undefined}>
                                        {headers.map((header, headerIndex) => (
                                            <td key={headerIndex}>{String(item[header]).replace(",", "  ")}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3" style={{ padding: '0.5rem' }}>
                        <button className="btn btn-primary" onClick={handlePrevious} disabled={!pagination.previous}>
                            Previous
                        </button>
                        <div className="pagination-buttons" style={{ display: 'flex', gap: '0.5rem' }}>
                            {renderPagination()}
                        </div>
                        <button className="btn btn-primary" onClick={handleNext} disabled={!pagination.next}>
                            Next
                        </button>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ReadResource;
