import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStepBackward, faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import Header from "./Header";
import DataTableStatic from "./DataTableStatic";
import { hostUrl,apiUrl } from './config'; // Import API_URL and dataUrl from config.js

// Setup Axios Instance
const getAccessToken = () => localStorage.getItem('access_token');
const getRefreshToken = () => localStorage.getItem('refresh_token');
const setAccessToken = (token) => localStorage.setItem('access_token', token);

const refreshAccessToken = async () => {
    try {
        const response = await axiosInstance.post(`${apiUrl}/token/refresh/`, { refresh: getRefreshToken() });
        setAccessToken(response.data.access);
        return response.data.access;
    } catch (error) {
        console.error('Failed to refresh access token', error);
        return null;
    }
};

const axiosInstance = axios.create({
    baseURL: apiUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(
    async (config) => {
        let token = getAccessToken();
        if (token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (decodedToken.exp < currentTime) {
                token = await refreshAccessToken();
            }
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const newToken = await refreshAccessToken();
            if (newToken) {
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                return axiosInstance(originalRequest);
            }
        }
        return Promise.reject(error);
    }
);

// DeviceForm1 Component
const DeviceForm1 = ({ isReadOnly }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formConfig, setFormConfig] = useState([]);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [custom_user, setCustom_user] = useState([]);
    const [organization, setOrganization] = useState([]);
    const [contact, setContact] = useState([]);
    const isNewForm = !id || id === 'new';

    useEffect(() => {
        const fetchFormConfig = async () => {
            try {
                const response = await axiosInstance.get(`${hostUrl}/getHeaders/device/`);
                const data = response.data;
                setFormConfig(data);
                if (id && !isNewForm) {
                    try {
                        const dataResponse = await axiosInstance.get(`${hostUrl}/device/${id}/?format=json`);
                        const formData = dataResponse.data;
                        setFormData(formData);
                    } catch (error) {
                        throw new Error('Error fetching device data');
                    }
                } else {
                    setFormData({});
                }
                setLoading(false);
            } catch (error) {
                console.error('Fetch error:', error);
                setError(error.message);
                setLoading(false);
            }
        };

        const fetchCustomers = async () => {
            try {
                const response = await axiosInstance.get(`${hostUrl}/custom_user/?format=json`);
                const data = response.data;
                setCustom_user(data.results.map(custom_user => ({ label: custom_user.user, value: custom_user.id })));
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

        const fetchOrganizations = async () => {
            try {
                const response = await axiosInstance.get(`${hostUrl}/organization/?format=json`);
                const data = response.data;
                setOrganization(data.results.map(org => ({ label: org.name, value: org.id })));
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

        const fetchContacts = async () => {
            try {
                const response = await axiosInstance.get(`${hostUrl}/contact/?format=json`);
                const data = response.data;
                setContact(data.results.map(contact => ({ label: contact.email, value: contact.id })));
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

        fetchFormConfig();
        fetchCustomers();
        fetchOrganizations();
        fetchContacts();
    }, [id]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleCustomerChange = (selectedOptions) => {
        setFormData({
            ...formData,
            custom_user: selectedOptions ? selectedOptions.map(option => option.value) : []
        });
    };

    const handleOrganizationChange = (selectedOptions) => {
        setFormData({
            ...formData,
            organization: selectedOptions ? selectedOptions.map(option => option.value) : []
        });
    };

    const handleContactChange = (selectedOptions) => {
        setFormData({
            ...formData,
            contact: selectedOptions ? selectedOptions.map(option => option.value) : []
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const method = isNewForm ? 'POST' : 'PUT';
        const url = isNewForm ? `${hostUrl}/device/` : `${hostUrl}/device/${id}/`;

        try {
            const response = await axiosInstance({
                method: method,
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: formData,
            });

            setMessage(`Form submitted successfully: ${JSON.stringify(response.data, null, 2)}`);

            setTimeout(() => {
                setMessage(null);
                navigate('/device');
            }, 4000);
        } catch (error) {
            console.error('Submit error:', error);
            setError(`Submit error: ${error.message}`);
            setTimeout(() => setError(null), 10000);
        }
    };

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            try {
                const response = await axiosInstance.delete(`${hostUrl}/device/${id}/`);

                if (!response.status === 204) {
                    throw new Error('Error deleting item');
                }

                setMessage('Item deleted successfully');
                setTimeout(() => navigate('/device'), 2000);
            } catch (error) {
                console.error('Delete error:', error);
                setError(`Delete error: ${error.message}`);
                setTimeout(() => setError(null), 10000);
            }
        }
    };

    const getSelectOptions = (label) => {
        const field = formConfig.find(item => item.label === label);
        return field ? field.choices : [];
    };

    const isFieldRequired = (label) => {
        const field = formConfig.find(item => item.label === label);
        return field ? field.required === 'True' : false;
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container">
            <span className="display-5 p-2">Overview</span>

            <div className="row mt-3">
                <div className="col-6 text-center">
                    <h6 className="text-muted">STATUS</h6>
                    <h4>OK</h4>
                </div>
                <div className="col-6 text-center">
                    <h6 className="text-muted">LAST REPORTED</h6>
                    <h4>4 minutes ago</h4>
                </div>
            </div>
            {message && <div className="alert alert-success"><pre>{message}</pre></div>}
            {error && <div className="alert alert-danger"><pre>{error}</pre></div>}
            <form className="row mt-5 w-100" onSubmit={handleSubmit}>
                <div className="col-4">
                    <div className="mb-3">
                        <label htmlFor="IdInputSerial_Number" className="form-label">Serial Number</label>
                        <input
                            type="text"
                            value={formData.serial_number || ''}
                            onChange={handleChange}
                            className="form-control"
                            id="IdInputSerial_Number"
                            name="serial_number"
                            required={isFieldRequired('serial_number')}
                            disabled={isReadOnly}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Status</label>
                        <select
                            className="form-select"
                            name="status"
                            value={formData.status || ''}
                            onChange={handleChange}
                            required={isFieldRequired('status')}
                            disabled={isReadOnly}
                        >
                            {getSelectOptions('status').map(option => (
                                <option key={option[1]} value={option[1]}>{option[0]}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="IdInputName" className="form-label">Name</label>
                        <input
                            type="text"
                            value={formData.name || ''}
                            onChange={handleChange}
                            className="form-control"
                            id="IdInputName"
                            name="name"
                            required={isFieldRequired('name')}
                            disabled={isReadOnly}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="IdInputLatitude" className="form-label">Latitude</label>
                        <input
                            type="number"
                            value={formData.latitude || ''}
                            onChange={handleChange}
                            className="form-control"
                            id="IdInputLatitude"
                            name="latitude"
                            required={isFieldRequired('latitude')}
                            disabled={isReadOnly}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="IdInputLongitude" className="form-label">Longitude</label>
                        <input
                            type="number"
                            value={formData.longitude || ''}
                            onChange={handleChange}
                            className="form-control"
                            id="IdInputLongitude"
                            name="longitude"
                            required={isFieldRequired('longitude')}
                            disabled={isReadOnly}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Unit Type</label>
                        <select
                            className="form-select"
                            name="unit_type"
                            value={formData.unit_type || ''}
                            onChange={handleChange}
                            required={isFieldRequired('unit_type')}
                            disabled={isReadOnly}
                        >
                            {getSelectOptions('unit_type').map(option => (
                                <option key={option[1]} value={option[1]}>{option[0]}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Sensor Type</label>
                        <select
                            className="form-select"
                            name="sensor_type"
                            value={formData.sensor_type || ''}
                            onChange={handleChange}
                            required={isFieldRequired('sensor_type')}
                            disabled={isReadOnly}
                        >
                            {getSelectOptions('sensor_type').map(option => (
                                <option key={option[1]} value={option[1]}>{option[0]}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="IdInputInstallation_Date" className="form-label">Installation Date (yyyy-mm-dd)</label>
                        <input
                            type="date"
                            value={formData.installation_date || ''}
                            onChange={handleChange}
                            className="form-control"
                            id="IdInputInstallation_Date"
                            name="installation_date"
                            required={isFieldRequired('installation_date')}
                            disabled={isReadOnly}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="IdInputNotes" className="form-label">Notes</label>
                        <textarea
                            className="form-control"
                            id="IdInputNotes"
                            rows={5}
                            name="notes"
                            value={formData.notes || ''}
                            onChange={handleChange}
                            required={isFieldRequired('notes')}
                            disabled={isReadOnly}
                        />
                    </div>
                </div>
                <div className="col-4 pr-4">
                    <div className="mb-3">
                        <label htmlFor="IdInputAddress" className="form-label">Address</label>
                        <input
                            type="text"
                            value={formData.address || ''}
                            onChange={handleChange}
                            className="form-control"
                            id="IdInputAddress"
                            name="address"
                            required={isFieldRequired('address')}
                            disabled={isReadOnly}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="IdInputCity" className="form-label">City</label>
                        <input
                            type="text"
                            value={formData.city || ''}
                            onChange={handleChange}
                            className="form-control"
                            id="IdInputCity"
                            name="city"
                            required={isFieldRequired('city')}
                            disabled={isReadOnly}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">State</label>
                        <select
                            className="form-select"
                            name="state"
                            value={formData.state || ''}
                            onChange={handleChange}
                            required={isFieldRequired('state')}
                            disabled={isReadOnly}
                        >
                            {getSelectOptions('state').map(option => (
                                <option key={option[1]} value={option[1]}>{option[0]}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="IdInputZip" className="form-label">Zip</label>
                        <input
                            type="number"
                            value={formData.zip || ''}
                            onChange={handleChange}
                            className="form-control"
                            id="IdInputZip"
                            name="zip"
                            required={isFieldRequired('zip')}
                            disabled={isReadOnly}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Timezone</label>
                        <select
                            className="form-select"
                            name="timezone"
                            value={formData.timezone || ''}
                            onChange={handleChange}
                            required={isFieldRequired('timezone')}
                            disabled={isReadOnly}
                        >
                            {getSelectOptions('timezone').map(option => (
                                <option key={option[1]} value={option[1]}>{option[0]}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="IdInputSubscription_End_Date" className="form-label">Subscription End Date (yyyy-mm-dd)</label>
                        <input
                            type="date"
                            value={formData.subscription_end_date || ''}
                            onChange={handleChange}
                            className="form-control"
                            id="IdInputSubscription_End_Date"
                            name="subscription_end_date"
                            required={isFieldRequired('subscription_end_date')}
                            disabled={isReadOnly}
                        />
                    </div>
                </div>
                <div className="col-4 pr-4">
                    <div className="mb-3">
                        <label className="form-label">Custom User</label>
                        <Select
                            options={custom_user}
                            onChange={handleCustomerChange}
                            value={custom_user.filter(option => formData.custom_user && formData.custom_user.includes(option.value)) || null}
                            isMulti
                            isClearable
                            isDisabled={isReadOnly}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Organization</label>
                        <Select
                            options={organization}
                            onChange={handleOrganizationChange}
                            value={organization.filter(option => formData.organization && formData.organization.includes(option.value)) || null}
                            isMulti
                            isClearable
                            isDisabled={isReadOnly}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Contact</label>
                        <Select
                            options={contact}
                            onChange={handleContactChange}
                            value={contact.filter(option => formData.contact && formData.contact.includes(option.value)) || null}
                            isMulti
                            isClearable
                            isDisabled={isReadOnly}
                        />
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={!!formData.send_organization_vacuum_alerts}
                            onChange={handleChange}
                            name="send_organization_vacuum_alerts"
                            id="IdInputSend_Organization_Vacuum_Alerts"
                            required={isFieldRequired('send_organization_vacuum_alerts')}
                            disabled={isReadOnly}
                        />
                        <label htmlFor="IdInputSend_Organization_Vacuum_Alerts" className="form-label">Send Organization Vacuum Alerts</label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={!!formData.send_organization_power_alerts}
                            onChange={handleChange}
                            name="send_organization_power_alerts"
                            id="IdInputSend_Organization_Power_Alerts"
                            required={isFieldRequired('send_organization_power_alerts')}
                            disabled={isReadOnly}
                        />
                        <label htmlFor="IdInputSend_Organization_Power_Alerts" className="form-label">Send Organization Power Alerts</label>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="IdInputSite_RTN" className="form-label">Site RTN</label>
                        <input
                            type="text"
                            value={formData.site_rtn || ''}
                            onChange={handleChange}
                            className="form-control"
                            id="IdInputSite_RTN"
                            name="site_rtn"
                            required={isFieldRequired('site_rtn')}
                            disabled={isReadOnly}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="IdInputDevice_Number" className="form-label">Device Number</label>
                        <input
                            type="number"
                            value={formData.device_number || ''}
                            onChange={handleChange}
                            className="form-control"
                            id="IdInputDevice_Number"
                            name="device_number"
                            required={isFieldRequired('device_number')}
                            disabled={isReadOnly}
                        />
                    </div>
                </div>
                <h5 className="mt-2 p-1">Alerts</h5>
                <h4 className="text-muted">Vacuum</h4>
                <div className="mb-3">
                    <label htmlFor="IdTriggerAlertsBelowVacuumLevel" className="form-label">Trigger Alerts Below Vacuum Level</label>
                    <input
                        type="number"
                        value={formData.trigger_alerts_below_vacuum_level || ''}
                        onChange={handleChange}
                        className="form-control"
                        id="IdTriggerAlertsBelowVacuumLevel"
                        name="trigger_alerts_below_vacuum_level"
                        required={isFieldRequired('trigger_alerts_below_vacuum_level')}
                        disabled={isReadOnly}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="IdTriggerAlertsAboveVacuumLevel" className="form-label">Trigger Alerts Above Vacuum Level</label>
                    <input
                        type="number"
                        value={formData.trigger_alerts_above_vacuum_level || ''}
                        onChange={handleChange}
                        className="form-control"
                        id="IdTriggerAlertsAboveVacuumLevel"
                        name="trigger_alerts_above_vacuum_level"
                        required={isFieldRequired('trigger_alerts_above_vacuum_level')}
                        disabled={isReadOnly}
                    />
                </div>
                <h5 className="mt-2 p-1">Device check-ins</h5>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={!!formData.send_check_in_alerts}
                        onChange={handleChange}
                        name="send_check_in_alerts"
                        id="IdSend_Check_In_Alerts"
                        required={isFieldRequired('send_check_in_alerts')}
                        disabled={isReadOnly}
                    />
                    <label htmlFor="IdSend_Check_In_Alerts" className="form-label">Send Check In Alerts</label>
                </div>
                <div className="mb-3">
                    <label htmlFor="IdTriggerAlertsAfterHour" className="form-label">Trigger Alerts After (Hour)</label>
                    <input
                        type="number"
                        value={formData.trigger_alerts_after_hours || ''}
                        onChange={handleChange}
                        className="form-control"
                        id="IdTriggerAlertsAfterHour"
                        name="trigger_alerts_after_hours"
                        required={isFieldRequired('trigger_alerts_after_hours')}
                        disabled={isReadOnly}
                    />
                </div>
                <h4 className="mt-2">Options</h4>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={!!formData.suppress_all_emails}
                        onChange={handleChange}
                        name="suppress_all_emails"
                        id="IdSuppressAllEmails"
                        required={isFieldRequired('suppress_all_emails')}
                        disabled={isReadOnly}
                    />
                    <label htmlFor="IdSuppressAllEmails" className="form-label">Suppress All Emails</label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={!!formData.suppress_vac_alerts}
                        onChange={handleChange}
                        name="suppress_vac_alerts"
                        id="Idsuppress_vac_alerts"
                        required={isFieldRequired('suppress_vac_alerts')}
                        disabled={isReadOnly}
                    />
                    <label htmlFor="Idsuppress_vac_alerts" className="form-label">Suppress Vacuum Alerts</label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={!!formData.suppress_power_alerts}
                        onChange={handleChange}
                        name="suppress_power_alerts"
                        id="IdSuppressPowerAlerts"
                        required={isFieldRequired('suppress_power_alerts')}
                        disabled={isReadOnly}
                    />
                    <label htmlFor="IdSuppressPowerAlerts" className="form-label">Suppress Power Alerts</label>
                </div>
                <div className="text-center pt-2">
                    {!isReadOnly && (
                        <>
                            <button type="submit" className="btn btn-primary w-auto">Save</button>
                            {/*{!isNewForm && (*/}
                            {/*    <button type="button" className="btn btn-danger w-auto ms-2" onClick={handleDelete}>Delete</button>*/}
                            {/*)}*/}
                        </>
                    )}
                </div>
            </form>
        </div>
    );
};

// DeviceForm2 Component
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const DeviceForm2 = ({ realtimeLogUrl }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });

    useEffect(() => {
        if (realtimeLogUrl) {
            const fetchData = async () => {
                try {
                    const response = await axiosInstance.get(realtimeLogUrl);
                    const result = response.data;
                    setData(result.results);

                    if (result.results.length > 0) {
                        const latestEntry = result.results[0];
                        setMapCenter({
                            lat: parseFloat(latestEntry.lat),
                            lng: parseFloat(latestEntry.long),
                        });
                    }

                    setLoading(false);
                } catch (error) {
                    console.error('There was an error fetching the data!', error);
                    setError('There was an error fetching the data.');
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [realtimeLogUrl]);

    const getChartData = () => {
        const labels = data.map(entry => new Date(entry.ts).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }));
        const vacuumData = data.map(entry => parseFloat(entry.vacuum));

        return {
            labels,
            datasets: [
                {
                    label: 'Vacuum',
                    data: vacuumData,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        };
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Vacuum Over Time',
            },
        },
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="alert alert-danger" role="alert">{error}</div>;
    }

    return (
        <div className="container my-2">
            <div className="row p-1">
                <div className="col-3">
                    <h5 className="text-start">Map</h5>
                    <iframe
                        className="w-100 h-100 pb-4"
                        style={{ border: 0 }}
                        referrerPolicy="no-referrer-when-downgrade"
                        src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyChEn7SIujk9Dp5CxD53IyNRF5IgG9QVZw&center=${mapCenter.lat},${mapCenter.lng}&zoom=18`}
                        allowFullScreen=""
                    ></iframe>
                </div>
                <div className="col-9">
                    <Line options={chartOptions} data={getChartData()} />
                </div>
            </div>
        </div>
    );
};

// SingleDevice Component
const SingleDevice = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [deviceData, setDeviceData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [ssn, setSsn] = useState(null);
    const [isReadOnly, setIsReadOnly] = useState(false); // Add this state

    useEffect(() => {
        const checkUserRole = () => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user && !(user.is_staff || user.is_superuser)) {
                setIsReadOnly(true); // Set read-only state based on user role
            }
        };

        const fetchDeviceData = async () => {
            try {
                const response = await axiosInstance.get(`${hostUrl}/device/${id}/?format=json`);
                const data = response.data;
                setDeviceData(data);
                setSsn(data.serial_number);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        checkUserRole(); // Check user role on component mount

        if (id) {
            fetchDeviceData();
        } else {
            setLoading(false);
        }
    }, [id]);



        const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            try {
                const response = await axiosInstance.delete(`${hostUrl}/device/${id}/`);

                if (!response.status === 204) {
                    throw new Error('Error deleting item');
                }

                setMessage('Item deleted successfully');
                setTimeout(() => navigate('/device'), 2000);
            } catch (error) {
                console.error('Delete error:', error);
                setError(`Delete error: ${error.message}`);
                setTimeout(() => setError(null), 10000);
            }
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="alert alert-danger" role="alert">{error}</div>;
    }

    const realtimeLogUrl = ssn ? `${hostUrl}/realtime_log/?ssn=${ssn}&format=json&limit=100&offset=0` : '';

    return (
        <div className="d-flex flex-row">
            <Header />
            <main style={{ marginTop: '58px' }}>
                <div className="d-flex">
                    <div className="content flex-grow-1 mt-4">
                        <div className="container mt-4">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <button className="btn btn-secondary" onClick={() => navigate(-1)}>
                                    <FontAwesomeIcon icon={faStepBackward} /> Back
                                </button>
                                {!isReadOnly && (
                                    <button className="btn btn-danger text-nowrap" onClick={handleDelete}>
                                        <FontAwesomeIcon icon={faDeleteLeft} /> Delete
                                    </button>
                                )}
                            </div>
                            {message && <div className="alert alert-success"><pre>{message}</pre></div>}
                            {error && <div className="alert alert-danger"><pre>{error}</pre></div>}
                            <DeviceForm1 isReadOnly={isReadOnly} />
                            <DeviceForm2 realtimeLogUrl={realtimeLogUrl} />

                            {deviceData && (
                                <div className="mt-3">
                                    <div className="container">
                                        <h3 className="mt-2">Client Contacts</h3>
                                        <DataTableStatic resource={`get_all_emails_by_device_id/${id}`}  />
                                    </div>
                                    <div className="container">
                                        <h4 className="text-start mt-2">Email Logs</h4>
                                        <DataTableStatic resource={"email_log"} filterField={"device"} filterValue={id} />
                                    </div>
                                    <div className="container">
                                        <h4 className="container text-start mt-2">Data</h4>
                                        <DataTableStatic resource={"realtime_log"} filterField={"ssn"} filterValue={ssn} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default SingleDevice;
