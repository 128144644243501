// DataTableStatic.js

import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import useForceUpdate from 'use-force-update';
import {axiosInstance} from './axiosInstance';
import {hostUrl} from "./config";

const DataTableStatic = (props) => {
    const forceUpdate = useForceUpdate();
    const { resource, filterField, filterValue } = props;
    const [data, setData] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize] = useState(50);

    useEffect(() => {
        fetchData(`${hostUrl}/${resource}/?format=json&limit=${pageSize}&offset=${(currentPage - 1) * pageSize}`);
    }, [hostUrl, resource, filterField, filterValue, currentPage]);

    const fetchData = (url) => {
        let apiUrl = url;
        if (filterField && filterValue) {
            const separator = url.includes('?') ? '&' : '?';
            apiUrl = `${url}${separator}${filterField}=${filterValue}`;
            console.log(apiUrl);
        }

        axiosInstance.get(apiUrl)
            .then(response => {
                const responseData = response.data.results || response.data;
                setData(responseData);

                if (responseData.length > 0) {
                    const keys = Object.keys(responseData[0]);
                    setHeaders(keys);
                } else {
                    setHeaders([]);
                }

                if (response.data.count) {
                    setTotalPages(Math.ceil(response.data.count / pageSize));
                }
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
                setError('There was an error fetching the data.');
            });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    if (error) {
        return <div className="alert alert-danger" role="alert">{error}</div>;
    }

    return (
        <div className="container mt-2">
            {data.length > 0 ? (
                <>
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={index} scope="col">{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    {headers.map((header, headerIndex) => (
                                        <td key={headerIndex}>{item[header]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </>
            ) : (
                <div>No data available.</div>
            )}
        </div>
    );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(currentPage - 1)}>&laquo; Previous</button>
                </li>
                {pageNumbers.map((number) => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button className="page-link" onClick={() => onPageChange(number)}>{number}</button>
                    </li>
                ))}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(currentPage + 1)}>Next &raquo;</button>
                </li>
            </ul>
        </nav>
    );
};

export default DataTableStatic;
