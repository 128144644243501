// GeneralizedForm.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStepBackward, faTrash } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { apiUrl, hostUrl } from './config';

const GeneralizedForm = ({ formConfigUrl }) => {
    const { resource, id } = useParams();
    const navigate = useNavigate();
    const [formConfig, setFormConfig] = useState([]);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    let local_id = '';
    if (id === undefined) {
        local_id = 'new';
    }

    const getAccessToken = () => localStorage.getItem('access_token');
    const getRefreshToken = () => localStorage.getItem('refresh_token');
    const setAccessToken = (token) => localStorage.setItem('access_token', token);

    const refreshAccessToken = async () => {
        try {
            const response = await axios.post(`${apiUrl}/token/refresh/`, {
                refresh: getRefreshToken(),
            });
            setAccessToken(response.data.access);
            return response.data.access;
        } catch (error) {
            console.error('Failed to refresh access token', error);
            return null;
        }
    };

    const axiosInstance = axios.create();

    axiosInstance.interceptors.request.use(
        async (config) => {
            let token = getAccessToken();
            if (token) {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000;

                if (decodedToken.exp < currentTime) {
                    token = await refreshAccessToken();
                }
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;
            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const newToken = await refreshAccessToken();
                if (newToken) {
                    originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                    return axiosInstance(originalRequest);
                }
            }
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const configResponse = await axiosInstance.get(`${formConfigUrl}/${resource}/`);
                const configData = configResponse.data;
                const enhancedFormConfig = configData.map((field) => ({
                    ...field,
                    name: field.label.toLowerCase().replace(/\s+/g, '_'),
                }));
                setFormConfig(enhancedFormConfig);

                if (id && id !== 'new') {
                    const dataResponse = await axiosInstance.get(`${hostUrl}/${resource}/${id}/?format=json`);
                    const data = dataResponse.data;
                    setFormData(formatDateTimeLocal(data, enhancedFormConfig));
                }
                setLoading(false);
            } catch (error) {
                console.error('Error:', error);
                setError(error.message);
                setShowErrorModal(true);
                setLoading(false);
            }
        };

        fetchData();
    }, [formConfigUrl, resource, id]);

    const formatDateTimeLocal = (data, formConfig) => {
        const formattedData = { ...data };
        formConfig.forEach((field) => {
            if (field.type === 'datetime-local' && formattedData[field.name]) {
                const date = new Date(formattedData[field.name]);
                const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
                formattedData[field.name] = formattedDate;
            }
        });
        return formattedData;
    };

    const handleChange = (e) => {
        const { name, value, type, checked, multiple, selectedOptions } = e.target;
        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked,
            });
        } else if (multiple) {
            const values = Array.from(selectedOptions).map((option) => option.value);
            setFormData({
                ...formData,
                [name]: values,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const method = local_id === 'new' ? 'POST' : 'PUT';
        const url = local_id === 'new' ? `${hostUrl}/${resource}/` : `${hostUrl}/${resource}/${id}/`;

        axiosInstance({
            method: method,
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                setModalMessage(`Form submitted successfully:\n${JSON.stringify(response.data, null, 2)}`);
                setShowSuccessModal(true);
            })
            .catch((error) => {
                console.error('Error submitting form:', error);
                setModalMessage(`Error submitting form:\n${error.message}`);
                setShowErrorModal(true);
            });
    };

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            axiosInstance({
                method: 'DELETE',
                url: `${hostUrl}/${resource}/${id}/`,
            })
                .then((response) => {
                    setModalMessage('Item deleted successfully');
                    setShowSuccessModal(true);
                })
                .catch((error) => {
                    console.error('Error deleting item:', error);
                    setModalMessage(`Error deleting item:\n${error.message}`);
                    setShowErrorModal(true);
                });
        }
    };

    const formatLabel = (label) => label.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());

    const renderField = (field) => {
        if (field.choices) {
            return (
                <select
                    multiple={field.multiple === 'True'}
                    className="form-select"
                    name={field.name}
                    value={formData[field.name] || (field.multiple === 'True' ? [] : '')}
                    onChange={handleChange}
                    required={field.required === 'True'}
                    size={field.multiple === 'True' ? '4' : undefined}
                >
                    <option value="">Select...</option>
                    {field.choices.map((choice, index) => (
                        <option key={index} value={choice[1]}>
                            {choice[0]}
                        </option>
                    ))}
                </select>
            );
        }

        switch (field.type) {
            case 'textarea':
                return (
                    <textarea
                        className="form-control"
                        name={field.name}
                        value={formData[field.name] || ''}
                        onChange={handleChange}
                        required={field.required === 'True'}
                    />
                );
            case 'checkbox':
                return (
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name={field.name}
                            checked={!!formData[field.name]}
                            onChange={handleChange}
                            required={field.required === 'True'}
                        />
                        <label className="form-check-label">{formatLabel(field.label)}</label>
                    </div>
                );
            case 'datetime-local':
                return (
                    <input
                        className="form-control"
                        type="datetime-local"
                        name={field.name}
                        value={formData[field.name] || ''}
                        onChange={handleChange}
                        required={field.required === 'True'}
                    />
                );
            default:
                return (
                    <input
                        className="form-control"
                        type={field.type}
                        name={field.name}
                        value={formData[field.name] || ''}
                        onChange={handleChange}
                        required={field.required === 'True'}
                    />
                );
        }
    };

    return (
        <div className="d-flex flex-row">
            <Header />
            <main style={{ marginTop: '58px' }}>
                <div className="d-flex">
                    <div className="content flex-grow-1 mt-4">
                        <div className="container mt-4">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <button className="btn btn-secondary" onClick={() => navigate(`/${resource}`)}>
                                    <FontAwesomeIcon icon={faStepBackward} /> Back
                                </button>
                                <h4>{id === 'new' ? `Create ${formatLabel(resource)}` : `Update ${formatLabel(resource)}`}</h4>
                                {id !== 'new' && (
                                    <button className="btn btn-danger" onClick={handleDelete}>
                                        <FontAwesomeIcon icon={faTrash} /> Delete
                                    </button>
                                )}
                            </div>

                            <form onSubmit={handleSubmit} className="p-3 border rounded">
                                {loading ? (
                                    <div>Loading...</div>
                                ) : error ? (
                                    <div className="alert alert-danger">Error: {error}</div>
                                ) : (
                                    <div className="row">
                                        {formConfig.map((field, index) => (
                                            <div className="col-md-6 mb-3" key={index}>
                                                <div className="form-group">
                                                    <label className="form-label">{formatLabel(field.label)}</label>
                                                    {renderField(field)}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <button className="btn btn-primary w-100 mt-3" type="submit">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </main>

            <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre>{modalMessage}</pre>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSuccessModal} onHide={() => { setShowSuccessModal(false); navigate(`/${resource}`); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre>{modalMessage}</pre>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowSuccessModal(false); navigate(`/${resource}`); }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default GeneralizedForm;
