import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { apiUrl } from './config';

// Function to get the access token from local storage
const getAccessToken = () => localStorage.getItem('access_token');

// Function to get the refresh token from local storage
const getRefreshToken = () => localStorage.getItem('refresh_token');

// Function to set the access token in local storage
const setAccessToken = (token) => localStorage.setItem('access_token', token);

// Function to fetch new access token using the refresh token
const refreshAccessToken = async () => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/token/refresh/`, {
      refresh: getRefreshToken(),
    });
    setAccessToken(response.data.access);
    const decodedAccessToken = jwtDecode(response.data.access);
    localStorage.setItem('user', JSON.stringify(decodedAccessToken));
    return response.data.access;
  } catch (error) {
    console.error('Failed to refresh access token', error);
    return null;
  }
};

// Axios instance with interceptors
const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let token = getAccessToken();
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        token = await refreshAccessToken();
      }
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newToken = await refreshAccessToken();
      if (newToken) {
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return axiosInstance(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

// Authentication functions
const login = async (username, password) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/token/`, { username, password });
    if (response.data.access && response.data.refresh) {
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);

      const decodedAccessToken = jwtDecode(response.data.access);
      console.log(JSON.stringify(decodedAccessToken));
      alert(JSON.stringify(decodedAccessToken));
      localStorage.setItem('user', JSON.stringify(decodedAccessToken));
    }
    return response.data;
  } catch (error) {
    console.error('Login error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const logout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('user');
  window.location.reload();
};

const getCurrentUser = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user ? user.username : null;
};

export { axiosInstance, login, logout, getCurrentUser, refreshAccessToken };
